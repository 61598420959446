/* page content */
.page {
	padding-bottom: 35px;
	&.contacts {
		padding-bottom: 0px;
	}
	.news1 {
		.date {
			color: #1da753;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #1da753;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #1da753;
		}
	}
	a {
		color: #292929;
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #1da753;
			text-decoration: none;
		}
	}
	h1 {
		font-size: 30px;
	}
	h2 {
		margin-top: 35px;
	}
    .download {
        margin: 10px 0;
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		h1 {
			font-size: 28px;
		}
	}
	@media screen and (max-width: 768px) {
		h1 {
			font-size: 24px;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		p.img-small {
			img {
				width: 50%!important;
				margin: 0 auto!important;
			}
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
        .col-lg-12 table {
            width: 100%!important;
        }
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
	margin-top: 10px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #1da753;
			@include inline-block;
			a {
				font-size: 14px;
				color: #666666;
				text-decoration: none;
			}
			&:after {
				content: '/';
				font-size: 12px;
				margin: 1px 2px 0px 7px;
				color: #666666;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

/* page about */
.about__slider {
	margin: 15px -15px!important;
	.slick-slide {
		outline: none;
		margin: 0 15px;
		img {
			display: block;
			width: 100%;
		}
	}
	.slick-arrow {
		display: block;
		width: 30px;
		height: 30px;
		background-color: #fff;
		background-size: contain;
		text-align: center;
		border-radius: 100%;
		border: 2px solid #1da753;
		transition: 350ms;
		opacity: 0.8;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			display: block;
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #1da753;
			line-height: 1;
			text-align: center;
		}
		&:hover {
			opacity: 1;
		}
		&.slick-prev {
			left: 0px;
			&:after {
				content: '\f104';
				margin-left: -2px;
			}
		}
		&.slick-next {
			right: 0px;
			&:after {
				content: '\f105';
				margin-right: -2px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		padding: 0px 25px;
		.slick-arrow {
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
}
/* page about */

/* page catalog */
.cat__col {
	display: block;
	text-decoration: none!important;
	text-align: center;
	margin-bottom: 25px;
	.cat__col__img {
		height: 200px;
		overflow: hidden;
		position: relative;
		img {
			display: block;
			transition: 400ms;
		}
		.cat__col__img__icon {
			height: 50px;
			width: auto;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			margin: -25px auto 0;
			z-index: 1;
			img {
				width: auto;
				height: 100%;
				margin: 0 auto;
			}
		}
	}
	h4 {
		padding: 10px 0 5px;
        margin-bottom: 0;
        text-align: center;
		transition: 350ms;
	}
	p {
        text-align: center;
		margin: 0;
	}
	&:hover {
		.cat__col__img {
			img {
				transform: scale(1.1);
			}
			.cat__col__img__icon {
				img {
					transform: scale(1);
					&.hide {
						display: block!important;
					}
					&.vis {
						display: none;
					}
				}
			}
		}

		h4 {
			color: #1da753;
		}
	}
	@media screen and (max-width: 767px) {
		.cat__col__img {
			height: auto;
			text-align: center;
			img {
				width: 100%;
			}
		}
	}
}
.cat--right {
	.cat__col {
		text-align: left;
	}
}


/* page sidebar */
.sidebar {
	ul {
		@include ul-default;
		li a {
			text-decoration: none;
		}
	}
	
	.sidebar__btn {
		display: none;
	}

	/* page sidebar nav */
	.sidebar__nav {
		background-color: #f3f2f2;
		padding: 0 20px;
		margin-bottom: 25px;
		> ul {
			> li {
				padding: 15px 0px 15px 0px;
				border-bottom: 1px solid #dadada;
				&:last-of-type {
					border-bottom: none;
				}
				> a {
					display: block;
					font-family: $bold;
					line-height: 1.2;
					padding-right: 25px;
					position: relative;
					&:hover {
						color: #292929;
					}
					&:after, &:before {
						content: '';
						display: block;
						position: absolute;
						width: 12px;
						height: 1px;
						background-color: #292929;
						line-height: 1;
						top: 50%;
						right: 0px;
						transition: 350ms;
					}
					&:before {
						transform: rotate(90deg);
					}
				}
				&.active > a {
					&:before {
						opacity: 0;
					}
				}
				ul {
					margin-top: 5px;
					display: none;
					li {
						position: relative;
						padding-left: 40px;
						&:after {
							content: '';
							width: 7px;
							height: 7px;
							background-color: transparent;
							border-radius: 100%;
							border: 1px solid #292929;
							position: absolute;
							left: 25px;
							top: 9px;
						}
						a:hover {
							color: #1da753;
							text-decoration: underline;
						} 
						&.active a {
							color: #1da753;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	/* page sidebar nav */
	
	@media  screen and (max-width: 991px) {
		max-height: 42px;
		margin-bottom: 25px;
		overflow: hidden;
		transition: 500ms;
		&.open {
			max-height: 1500px;
		}
		.sidebar__btn {
			display: block;
			width: 100%;
			position: relative;
			font-family: $bold;
			font-size: 16px;
			background-color: #1da753;
			text-decoration: none;
			text-transform: uppercase;
			padding: 10px;
			color: #fff;
			&:after, &:before {
				content: '';
				display: block;
				position: absolute;
				width: 15px;
				height: 2px;
				background-color: #fff;
				line-height: 1;
				top: 50%;
				right: 15px;
				transition: 350ms;
			}
			&:before {
				transform: rotate(90deg);
			}
			&:hover {
				color: #fff;
			}
			&.active {
				&:before {
					opacity: 0;
				}
			}
		}
	}
}
/* page sidebar */

/* page filter */
.page--filter {
	display: block;
	position: relative;
	padding: 20px 20px 20px 20px;
	margin: 15px 0px;
	background-color: #e3f9ec;
	text-align: right;
	h5 {
		margin: 0 0 15px 0;
		text-align: left;
	}
	ul {
		@include ul-default;
		display: block;
		margin-bottom: 10px;
		text-align: left;
		li {
			margin: 0 10px 10px 0;
			@include inline-block;
			.filter {
				display: block;
				position: relative;
				padding-left: 22px;
				font-weight: 100;
				cursor: pointer;
				user-select: none;
				&:after {
					content: '';
					background: url(../img/uncheck.jpg) no-repeat center center;
					background-size: contain;
					width: 15px;
					height: 15px;
					position: absolute;
					top: 3px;
					left: 0;
				}
			}
			input {
				position: absolute;
				opacity: 0;				}
			input[type="checkbox"]:checked + .filter:after {
				background: url(../img/check.jpg) no-repeat center center;
			}
		}
	}
	.btn {
		padding: 7px 20px 6px!important;
		text-decoration: none;
		@include inline-block;
	}
	@media screen and (max-width: 768px) {
		.btn {
			padding: 4px 10px 5px 10px!important;
			height: auto;
		}
	}
}
/* page filter */

/* page catalog */

/* page good */
.good__slider__for {
	.slick-slide {
        height: 335px;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
		}
		&:after {
			content: '\f00e';
			display: block;
			width: 50px;
			height: 50px;
			background: #1da753;
			font-family: 'fontAwesome';
			font-size: 25px;
			line-height: 1;
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			padding-top: 12px;
			margin: -25px auto;
			text-decoration: none;
			border-radius: 100%;
			box-sizing: border-box;
			color: #fff;
			opacity: 0;
			transition: 350ms;
		}
		&:hover:after {
			opacity: 1;	
		}
	}
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
            height: 250px;
        }
    }
}
.good__slider__nav {
	@extend .about__slider;
	margin: 15px -10px!important;
	.slick-slide {
		margin: 0 10px;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(29, 167, 83, 0.7);
			opacity: 0;
			transition: 350ms;
		}
		&:hover:after, &.slick-current:after {
			opacity: 1;
		}
		img {
			display: block;
			height: 70px;
            width: 100%;
            object-fit: cover;
            object-position: center center;
			margin: 0 auto;
		}
	}
	.slick-arrow {
		&.slick-prev {
			left: -7px;
		}
		&.slick-next {
			right: -7px;
		}
	}
    @media screen and (max-width: 991px) {
        .slick-slide {
            img {
                height: 120px;
            }
        }
    }
	@media screen and (max-width: 767px) {
		padding: 0 35px;
		.slick-slide {
			img {
				display: block;
				width: 100%;
				height: 100px;
			}
		}
		.slick-arrow {
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
    @media screen and (max-width: 420px) { 
        .slick-slide {
            img {
                height: 70px;
            }
        }
    }
}

.animals {
	@include ul-default;
	display: block;
	margin: 15px 0px;
	li {
		max-width: 60px;
		margin: 0px 15px 0px 0px;
		@include inline-block;
		img {
			display: block;
			max-width: 100%;
		}
	}
}

.download {
	display: block;
	position: relative;
	padding-left: 30px;
	color: #1da753!important;
	&:after {
		content: '\f019';
		display: block;
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #1da753;
		line-height: 1;
		position: absolute;
		top: 2px;
		left: 0;
	}
}

.table-characteristic {
	border-collapse: collapse;
	font-size: 18px;
	margin-bottom: 15px;
	tr {
		&:nth-of-type(even) {
			background-color: #e3f9ec;
		}
	}
	th, td {
		padding: 5px 10px;
	}
}

.good__related {
	@extend .about__slider;
	.slick-slide {
		text-align: left;
		text-decoration: none;
		h5 {
			text-align: left;
			margin-bottom: 5px;
			transition: 350ms;
		}
		&:hover {
			color: #1da753;
			h5 {
				color: #1da753;
			}
		}
	}
	.slick-arrow {
		margin-top: -30px;
	}
	@media screen and (max-width: 767px) {
		padding: 0 35px;
		.slick-arrow {
			&.slick-prev {
				left: 15px;
			}
			&.slick-next {
				right: 15px;
			}
		}
	}
}
/* page good */

/* page pagination */
.pagination {
	ul {
		@include ul-default;
		li {
			margin-bottom: 10px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 30px;
				height: 30px;
				display: block;
				border: 2px solid transparent;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 5px 2px 0px;
				outline: none;
				line-height: 1;
				&:hover {
					border: 2px solid #1da753;
					color: #292929;
				}
			}
			&.active {
				a {
					border: 2px solid #1da753;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					&:hover {
						border: 2px solid transparent;
					}
					&:after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-top: 5px solid transparent;
						border-bottom: 5px solid transparent;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -5px auto;
						transition: 350ms;
					}
				}
			}	
			&.prev a {
				&:after {
					border-right: 7px solid #292929;
				} 
				&:hover:after {
					border-right: 7px solid #1da753;
				}
			}
			&.next a {
				&:after {
					border-left: 7px solid #292929;
				} 
				&:hover:after {
					border-left: 7px solid #1da753;
				}
			}
		}
	}
}
/* page pagination */

/* page contacts */
.map {
	min-height: 320px;
	position: relative;
	margin-bottom: 25px;
	.map__block {
		width: 320px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.7);
		padding: 25px 30px;
		box-sizing: border-box;
		color: #fff;
		z-index: 1;
		h6 {
			margin-bottom: 20px;
		}
		ul {
			@include ul-default;
			li {
				position: relative;
				padding-left: 30px;
				margin-bottom: 10px;
				&:after {
					position: absolute;
					top: 3px;
					left: 0;
					line-height: 1;
					font-family: 'fontAwesome';
					font-size: 20px;
					color: #fff;
				}
				&.map__block__location:after {
					content: '\f041';
				}
				&.map__block__phone:after {
					content: '\f095';
				}
				&.map__block__email:after {
					content: '\f0e0';
					font-size: 16px;
				}
				a {
					display: block;
					text-decoration: none;
					color: #fff;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		h6 {
			color: #fff;
		}
	}
	@media screen and (max-width: 991px) {
		min-height: 250px;
		> ymaps {
			height: 250px!important;
			width: 100%!important;
		}
	}
	@media screen and (max-width: 767px) {
		min-height: 1px;
		.map__block {
			width: 100%;
			position: relative;
			padding: 20px;
			h6 {
				margin-top: 0;
			}
		}
		> ymaps {
			height: 200px!important;
			overflow: hidden;
		}
	}
}
.form {
	input[type="text"], input[type="email"], textarea {
		display: block;
		width: 100%;
		margin-bottom: 15px;
	}
	.g-recaptcha {
		float: left;
		margin-bottom: 5px;
	}
	.btn {
		float: right;
		padding-right: 60px!important;
		position: relative;
		margin-top: 15px;
		&:after {
			content: '';
			background-image: url(../img/right-arrow-white.svg);
			background-repeat: no-repeat;
			background-position: center right;
			background-size: contain;
			height: 20px;
			width: 25px;
			position: absolute;
			right: 20px;
			top: 8px;
			transition: 350ms;
		}
		&:hover:after {
			background-image: url(../img/right-arrow.svg);
		}
	}
	@media screen and (max-width: 480px) {
		.g-recaptcha {
			float: right;
			transform: scale(0.86);
			transform-origin: 100% 0;
			> div {
				float: right;
			}
		}
		.btn {
			margin-top: 0px;
		}
	}
}
/* page contacts */

/* modal */
.modal {
	.modal-content {
		border-radius: 0px;
		h3 {
			margin-top: 0px;
		}
		.close {
			position: absolute;
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			opacity: 1;
			right: 10px;
			top: 10px;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.btn {
			background-color: #ff0000;
			width: 100%!important;
		}
	}
	&#map__modal {
		.modal-content {
			h3 {
				margin-bottom: 15px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.modal-content h3 {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content {
			h3 {
				font-size: 18px;
				margin: 0;
			}
			.close {
				top: 16px;
			}
		}
	}
}
/* modal */

/* page content */