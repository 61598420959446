/* footer */
footer {
	background-color: #e3f9ec;
	padding-top: 50px;
	ul {
		@include ul-default;
	}
	h6 {
		margin-top: 0;
	}
	> .container {
		font-size: 0;
	}
	.footer__col {
		width: 20%;
		box-sizing: border-box;
		padding-right: 15px;
		font-size: 15px;
		@include inline-block;
		ul {
			li {
				display: block;
				position: relative;
				padding-left: 25px;
				margin-bottom: 10px;
				&:after {
					display: block;
					font-family: 'fontAwesome';
					font-size: 18px;
					color: #1da753;
					position: absolute;
					top: 2px;
					left: 0;
					line-height: 1;
				}
				&.footer__col__location:after {
					content: '\f041';
					left: 2px;
				}
				&.footer__col__phone:after {
					content: '\f095';
				}
				&.footer__col__email:after {
					content: '\f0e0';
					font-size: 14px;
					top: 3px;
				}
			}
		}
		&:nth-of-type(2) {
			width: 22%;
		}
		&:nth-of-type(3) {
			width: 18%;
		}
		&:nth-of-type(4) {
			width: 22%;
		}
		&:nth-of-type(5) {
			width: 18%;
		}
	}
	.container-fluid {
		background-color: #1da753;
		color: #fff;
		padding: 10px 0;
		margin-top: 40px;
		p {
			margin: 0;
			box-sizing: border-box;
			@include inline-block;
			&.artmedia {
				width: 240px;
				position: relative;
				padding-right: 120px;
				float: right;
				a {
					position: absolute;
					top: -2px;
					right: 0;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 20px;
		font-size: 14px;
		p, a {
			font-size: 14px;
		}
		.footer__col {
			width: 50%!important;
			margin-bottom: 15px;
		}
		.container-fluid {
			margin-top: 0px;
		}
	}
	@media screen and (max-width: 767px) {
		.footer__col {
			display: block;
			width: 100%;
		}
		.container-fluid {
			p {
				display: block;
				text-align: center;
				&.artmedia {
					text-align: left;
					margin: 5px auto 0;
					float: none;
				}
			}
		}
	}
}
/* footer */