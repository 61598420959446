/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url(../fonts/fontawesome-webfont.eot) format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 22, 2018 */
@font-face {
    font-family: 'clear_sansbold';
    src: url('../fonts/clearsans-bold-webfont.eot');
    src: url('../fonts/clearsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-bold-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-bold-webfont.woff') format('woff'),
         url('../fonts/clearsans-bold-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-bold-webfont.svg#clear_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'clear_sansregular';
    src: url('../fonts/clearsans-regular-webfont.eot');
    src: url('../fonts/clearsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-regular-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-regular-webfont.woff') format('woff'),
         url('../fonts/clearsans-regular-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-regular-webfont.svg#clear_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'clear_sansitalic';
    src: url('../fonts/clearsans-italic-webfont.eot');
    src: url('../fonts/clearsans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-italic-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-italic-webfont.woff') format('woff'),
         url('../fonts/clearsans-italic-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-italic-webfont.svg#clear_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'clear_sans_lightregular';
    src: url('../fonts/clearsans-light-webfont.eot');
    src: url('../fonts/clearsans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/clearsans-light-webfont.woff2') format('woff2'),
         url('../fonts/clearsans-light-webfont.woff') format('woff'),
         url('../fonts/clearsans-light-webfont.ttf') format('truetype'),
         url('../fonts/clearsans-light-webfont.svg#clear_sans_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
$regular: 'clear_sansregular';
$bold: 'clear_sansbold';
$light: 'clear_sans_lightregular';
$italic: 'clear_sansitalic';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-family: $regular;
	color: #292929;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 991px) {
		font-size: 16px;
	}
}

.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 18px;
	&.top {
		padding-top: 153px;
	}
	a, p {
		font-size: 18px;
	}
	textarea, input {
		min-height: 40px;
		font-family: $regular;
		font-size: 16px;
		background-color: #fff;
		padding: 5px 15px;
		border: 1px solid #1da753;
	}
	@media screen and (max-width: 1170px) {
		&.top {
			padding-top: 90px;
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 15px;
		font-size: 16px;
		a, p {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 767px) {
		&.top {
			padding-top: 80px;
		}
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	a:hover {
		text-decoration: none;
	}
}

textarea, input {
	border-radius: 0px;
}

h1 {
	font-size: 42px;
	font-family: $bold;
	text-transform: uppercase;
	color: #292929;
	display: block;
	text-align: center;
	padding-bottom: 30px;
	margin-bottom: 30px;
	position: relative;
	line-height: 1;
	text-align: center;
	&:after {
		content: '';
		width: 55px;
		height: 3px;
		background-color: #1da753;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0px;
		margin: 0 auto;
	}
	@media screen and (max-width: 991px) {
		font-size: 32px;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	font-size: 36px;
	font-family: $bold;
	color: #292929;
	display: block;
	margin-top: 0px;
	margin-bottom: 25px;
	text-align: center;
	a {
		font-size: 36px!important;
		font-family: $bold;
		color: #292929;
		display: block;
		text-decoration: none;
		&:hover {
			color: #1da753;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-size: 24px;
	font-family: $bold;
	color: #292929;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-size: 20px;
	font-family: $bold;
	color: #292929;
}
h5 {
	font-size: 18px;
	color: #292929;
	text-align: center;
	font-family: $bold;
}
h6 {
	font-size: 16px;
	text-transform: uppercase;
	color: #292929;
	font-family: $bold;
}
a {
	color: #292929;
	transition: 350ms;
	&:focus {
		color: #292929;
		text-decoration: none;
	}
	&:hover {
		color: #1da753;
	}
}
.btn {
	display: table;
	height: 40px;
	padding: 7px 40px 6px!important;
	font-size: 18px;
	color: #fff!important;
	background-color: #1da753!important;
	cursor: pointer;
	width: auto!important;
	border-radius: 0px;
	border: 2px solid #1da753;
	line-height: 1.2;
	transition: 350ms;
	&:focus, &:active {
		color: #fff;
		background-color: #1da753;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #1da753!important;
	}	
	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}

.check-ul {
	@include ul-default;
	li {
		display: block;
		padding-left: 30px;
		position: relative;
		margin-bottom: 5px;
		font-size: 18px;
		&:before {
			content: '\f00c';
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #1da753;
			position: absolute;
			top: 2px;
			left: 0;
			line-height: 1;
		}
	}
	@media screen and (max-width: 991px) {
		li {
			font-size: 16px;
			padding-left: 20px;
			&:before {
				font-size: 16px;
			}
		}
	}
}

/* header */
header {
	min-width: 300px;
	background-color: #fff;
	position: relative;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9;
	.container-fluid {
		background-color: #1da753;
	}
	.header__bottom {
		> .container {
			min-height: 103px;
		}
		&.fixed {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			background-color: #fff;
		}
	}
	.container {
		position: relative;
	}
	/* header banner */
	.banner {
		img {
			display: block;
			width: 100%;
		}
	}
	/* header banner */

	/* header logo */
	.logo {
		display: block;
		position: absolute;
		left: 15px;
		top: 0;
		z-index: 1;
		@include inline-block;
	}
	/* header logo */

	/* header phones */
	.phones {
		position: absolute;
		right: 15px;
		top: 35px;
		padding-left: 30px;
		&:after {
			content: '\f095';
			display: block;
			font-family: 'fontAwesome';
			font-size: 28px;
			color: #1da753;
			line-height: 1;
			position: absolute;
			top: 4px;
			left: 0;
		}
		ul {
			@include ul-default;
			li {
				display: block;
				a {
					font-size: 22px;
					font-family: $bold;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	/* header phones */

	/* header langs */
	.langs {
		position: absolute;
		top: 40px;
		right: 300px;
		ul {
			@include ul-default;
			li {
				@include inline-block;
				&:after {
					content: '';
					width: 2px;
					height: 24px;
					background-color: #1da753;
					margin: 0px 5px 0px 10px;
					line-height: 1;
					@include inline-block;
				}
				&:last-of-type:after {
					display: none;
				}
				a {
					font-size: 18px;
				}
				&.active a {
					text-decoration: underline;
				}
			}
		}
	}
	/* header langs */

	/* header nav */
	.navbar {
		min-height: 1px;
		margin: 0px 0px 0px 230px;
		border-radius: 0px;
		border: none;
		.navbar-nav {
			> li {
				> a {
					font-size: 20px;
					color: #fff;
					&:focus {
						background-color: transparent;
					}
					&:hover {
						text-decoration: none;
						background-color: transparent;
						text-decoration: underline;
					}
				}
				&.active > a {
					text-decoration: underline;
				}
			}
		}
	}
	/* header nav */

	/* header search */
	.search-btn {
		width: 20px;
		height: 20px;
		display: block;
		position: absolute;
		right: 15px;
		top: 14px;
		&:after {
			content: '\f002';
			display: block;
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #fff;
			line-height: 1;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.search {
		display: none;
		width: 250px;
		position: absolute;
		top: 50px;
		right: 15px;
		padding: 5px 10px;
		background-color: #fff;
		border: 2px solid #1da753;
		form {
			display: block;
			width: 100%;
		}
		input {
			outline: none;
			border: none;
			box-sizing: border-box;
			width: calc(100% - 20px);
			color: #292929;
		}
		.close {
			width: 20px;
			header: 20px;
			display: block;
			position: absolute;
			right: 0;
			top: 5px;
			opacity: 1;
			&:after {
				content: '\f00d';
				display: block;
				font-family: 'fontAwesome';
				font-size: 20px;
				color: #1da753;
				line-height: 1;
			}
		}
	}
	/* header search */

	@media screen and (max-width: 1199px) {
		.navbar {
			margin-left: 185px;
			.navbar-nav {
				> li > a {
					padding: 15px 5px;
				}
			}
		}
	}
	
	@media screen and (max-width: 1170px) {
		.logo {
			width: 115px;
			left: 0;
			right: 0;
			top: 5px;
			margin: 0 auto;
			img {
				display: block;
				width: 100%;
			}
		}

		.langs {
			right: auto;
			left: 65px;
		}

		.phones {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background-color: rgba(29, 167, 83, 0.9);
			padding: 10px;
			&:after {
				display: none;
			}
			ul li {
				a {
					display: block;
				 	font-size: 18px;
					color: #fff;
					text-align: center;
				}
			}
		}
		.phones-btn {
			display: block;
			position: absolute;
			top: 40px;
			right: 55px;
			font-size: 25px;
			color: #1da753;
			&:hover {
				text-decoration: none;
			}
			&:after {
				content: '\f095';
				display: block;
				font-family: 'fontAwesome';
				line-height: 1;
			}
		}

		.search-btn {
			top: -64px;
			&:after {
				color: #1da753;
			}
		}
		.search {
			top: 0;
		}

		.navbar {
			width: 100%;
			margin-left: 0;
			.navbar-nav {
				> li > a {
					font-size: 18px;
					padding: 10px 15px;
				}
			}
		}
		.navbar-toggle {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 0;
			top: -66px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #1da753;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.header__bottom > .container {
			min-height: 80px;
		}

		.logo {
			width: 90px;
		}

		.langs {
			top: 30px;
			left: 45px;
			ul {
				li {
					a {
						font-size: 16px;
					}
					&:after {
						height: 20px;
					}
				}
			}
		}

		.search-btn {
			top: -51px;
		}
		.search {
			width: 100%;
			right: 0;
		}

		.phones-btn {
			top: 29px;
			right: 50px;
		}

		.navbar-toggle {
			top: -55px;
			left: -10px;
		}
	}
}
/* header */