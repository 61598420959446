/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		&:after {
			content: '';
			display: block;
			background-color: rgba(0,0,0,0.3);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		img {
			width: 100%;
			height: auto;
			display: block;
			animation: 30s transform;
			-webkit-animation: 30s transform;
			-moz-animation: 30s transform;
			-ms-animation: 30s transform;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-ms-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			margin: 0 auto;
			margin-top: -50px;
			text-align: center;
			color: #fff;
			z-index: 1;
			.slider__block__title {
				font-size: 40px;
				display: block;
				text-transform: uppercase;
			}
			.slider__block__slogan {
				font-family: $bold;
				font-size: 46px;
				display: block;
				text-transform: uppercase;
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				background-color: transparent;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				border: 2px solid #fff;
				transition: 350ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #1da753;
					border: 2px solid #1da753;
				}
			}
			&.slick-active {
				button {
					background-color: #1da753;
					border: 2px solid #1da753;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 28px;
				}
				.slider__block__slogan {
					font-size: 22px;
				}
			}
			img {
				height: 280px;
				width: auto;
				animation: 0s transform;
				-webkit-animation: 0s transform;
				-moz-animation: 0s transform;
				-ms-animation: 0s transform;
				-webkit-animation-fill-mode: forwards;
				-moz-animation-fill-mode: forwards;
				-ms-animation-fill-mode: forwards;
				animation-fill-mode: forwards;
				transform: scale(1)!important;
			}
		}
		.slick-dots {
			bottom: 10px;
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			.slider__block {
				padding: 0px 10px;
				.slider__block__title {
					font-size: 24px;
				}
				.slider__block__slogan {
					font-size: 20px;
				}
			}
			img {
				margin-left: -180px;
			}
		}
	}
}
@-webkit-keyframes transform {
    0% {
   		transform: scale(1);
    }
    100% {
    	transform: scale(1.2);
    }
}
/* main slider */

/* main production */
.production {
	font-size: 0;
	.container {
		padding-bottom: 0;
	}
	.production__col {
		display: block;
		width: 16.6%;
		font-size: 18px;
		text-align: center;
		@include inline-block;
		.production__col__img {
			position: relative;
			overflow: hidden;
			margin-bottom: 10px;
			&:after {
				content: '';
				background-color: rgba(29, 167, 83, 0.7);
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transition: 350ms;
				opacity: 0;
			}
			img {
				display: block;
				width: 100%;
			}
			.production__col__img__icon {
				height: 60px;
				width: 60px;
				background-color: rgba(255,255,255,0.7);
				position: absolute;
				left: 0;
				bottom: 0;
				padding: 5px;
				box-sizing: border-box;
				overflow: hidden;
				z-index: 1;
				img {
                    display: block;
					width: 100%;
                    height: 100%;
                    object-position: center center;
                    object-fit: contain;
					margin: 0 auto;
				}
			}
		}
		p {
			font-family: $bold;
			font-size: 18px;
			line-height: 1.2;
			padding: 0 10px;
			transition: 350ms;
		}
		&:hover {
			.production__col__img {
				&:after {
					opacity: 1;
				}
				.production__col__img__icon {
					background-color: transparent;
					img {
						&.hide {
							display: block!important;
						}
						&.vis {
							display: none;
						}
					}
				}
			}
			p {
				color: #1da753;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.production__col {
			width: 33.333%;
			p {
				font-size: 16px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.production__col {
			width: 50%;
		}
	}
}
.quotes {
	font-family: $italic;
	font-size: 20px;
	color: #1da753;
	position: relative;
	padding-left: 30px;
	&:after {
		content: '\f10e';
		display: block;
		font-family: 'fontAwesome';
		font-size: 22px;
		color: #1da753;
		line-height: 1;
		position: absolute;
		left: 0;
		top: 5px;
	}
	@media screen and (max-width: 991px) {
		padding-left: 25px;
		&:after {
			font-size: 18px;
		}
	}
}
/* main production */

/* main partners */
.partners {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(29, 167, 83, 0.7);
	}
	.container {
		padding: 0 15px;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin-top: -40px;
		z-index: 1;
	}
	.partners__slider {
		margin: 0;
		.slick-slide {
			margin: 0 15px;
			img {
				display: block;
				width: 100%;
			}
		}
		.slick-arrow {
			display: block;
			width: 30px;
			height: 30px;
			background-size: contain;
			text-align: center;
			border-radius: 100%;
			border: 2px solid #fff;
			transition: 350ms;
			opacity: 0.7;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				display: block;
				font-family: 'fontAwesome';
				font-size: 20px;
				color: #fff;
				line-height: 1;
				text-align: center;
			}
			&:hover {
				opacity: 1;
			}
			&.slick-prev {
				&:after {
					content: '\f104';
					margin-left: -2px;
				}
			}
			&.slick-next {
				&:after {
					content: '\f105';
					margin-right: -2px;
				}
			}
		}
	}
	> img {
		display: block;
		width: 100%;
	}
	@media screen and (max-width: 991px) {
		&:after {
			display: none;
		}
		.container {
			position: relative;
			top: 0;
			margin: 0 auto;
		}
		.partners__slider .slick-arrow {
			color: #1da753;
			border: 2px solid #1da753;
			&:after {
				color: #1da753;
			}
		}
		> img {
			display: none;
		}
	}
	@media screen and (max-width: 767px) { 
		.partners__slider {
			padding: 0 35px;
			.slick-arrow {
				&.slick-prev {
					left: 0;
				}
				&.slick-next {
					right: 0;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.partners__slider {
			.slick-slide {
				img {
					width: 150px;
					margin: 0 auto;
				}
			}
		}
	}
}
/* main partners */

/* main news */
.news {
	.news__col {
		display: block;
		.news__col__img {
			overflow: hidden;
			img {
				width: 100%;
				transition: 350ms;
			}
		}
		.news__col__block {
			border-right: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
			border-left: 1px solid #e1e1e1;
			padding: 20px 25px;
			min-height: 200px;
			h4 {
				min-height: 45px;
				position: relative;
				padding-right: 35px;
				margin: 0;
				transition: 350ms;
				&:after {
					content: '';
					width: 30px;
					height: 30px;
					background: url(../img/next.svg) no-repeat center center;
					background-size: contain;
					position: absolute;
					right: 0;
					top: 6px;
				}
			}
			.news__date {
				display: block;
				color: #7e7e7e;
				margin: 4px 0;
			}
		}
		&:hover {
			color: #292929;
			box-shadow: 0px 0px 35px rgba(0,0,0,0.3);
			.news__col__img {
				img {
					transform: scale(1.1);
				}
			}
			.news__col__block {
				h4 {
					color: #1da753;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.news__col {
			.news__col__block {
				min-height: 1px;
				padding: 15px;
				margin-bottom: 25px;
				h4 {
					min-height: 1px;
					margin-bottom: 10px;
					&:after {
						top: 0;
					}
				}
			}
		}
	}
}
/* main news */


/* main content */